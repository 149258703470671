import React from 'react';
import PropTypes from 'prop-types';
import FileInputComponent from 'react-file-input-previews-base64';
import _ from 'lodash';

import { generateGuid } from './lib/guid-generator';

const validFileExtensions = [
  '.xlsx',
  '.xls',
  '.doc',
  '.docx',
  '.ppt,',
  '.pptx',
  '.pdf',
  '.jpg',
  '.jpeg',
  '.gif',
  '.png',
  '.txt'
];
const defaultMimeTypes = _.join(validFileExtensions, ',');

const FileUpload = ({
  field,
  form: { touched, errors },
  id,
  acceptedMimeTypes = defaultMimeTypes,
  onChange = () => {}
}) => {
  const inputId = id || generateGuid();
  const handleChange = (data) => {
    onChange(field.name, data);
  };

  const button = (<button className="btn blue darken-3 waves-effect waves-light" type="button">
    <i className="material-icons right">file_upload</i>Select File
  </button>);

  let errorField = null;
  if (touched[field.name] && errors[field.name]) {
    errorField = (
      <div className="form-error is-visible">
        <span>This is a required field</span>
      </div>
    );
  }

  return (
    <div className="file-upload single-file-upload">
      <FileInputComponent
        labelText="Select file"
        labelStyle={{ fontSize: 14, display: 'none' }}
        multiple={false}
        buttonComponent={button}
        inputId={inputId}
        callbackFunction={(fileData) => { handleChange(fileData); }}
        accept={acceptedMimeTypes}
      />
      {errorField}
    </div>
  );
};

FileUpload.propTypes = {
  id: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
      name: PropTypes.string,
      base64: PropTypes.string
    })
  }),
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  }),
  acceptedMimeTypes: PropTypes.string,
  onChange: PropTypes.func
};

export default FileUpload;
