import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Preloader from '../../lib/layout/Preloader';
import Modal from '../../lib/layout/Modal';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import FormSelectDropdown from '../../lib/forms/FormSelectDropdown';
import CheckBoxInput from '../../lib/forms/CheckBoxInput';
import TextInput from '../../lib/forms/TextInput';
import SubmitButton from '../../lib/forms/SubmitButton';

import {
  useGetAccountSettingsQuery,
  useUpdateAccountSettingsMutation
} from '../../lib/ascertis-api';

import AuthenticatorQrCode from './AuthenticatorQrCode';

const Schema = Yup.object().shape({
  phoneNumber: Yup.string().required(),
  mfaEnabled: Yup.bool().required(),
  mfaContactPreference: Yup.string().required()
});

const contactOptions = [
  { label: 'Email', value: 'email' },
  { label: 'SMS', value: 'sms' },
  { label: 'Authenticator App', value: 'authenticator' }
];

const AccountSettingsForm = () => {
  const [showQrCode, setShowQrCode] = useState(false);
  const [modalMessage, setModalMessage] = useState(undefined);
  const { data: settings } = useGetAccountSettingsQuery({});
  const [updateAccountSettings] = useUpdateAccountSettingsMutation();
  const showModal = !_.isEmpty(modalMessage);

  useEffect(() => {
    if (settings && settings.mfaContactPreference === 'authenticator') {
      setShowQrCode(true);
    }
  }, [settings])

  const handleSubmit = async (formValues) => {
    await updateAccountSettings({ params: formValues }).unwrap()
      .then((payload) => {
        // Take any success actions here
        setModalMessage('Account Settings Updated Successfully.');
      }).catch((error) => {
        setModalMessage(error);
      });
  };
  const handleClose = () => setModalMessage(undefined);

  if (!settings) {
    return <Preloader />;
  }

  let qrCodeArea = undefined;
  if (showQrCode && settings.authenticatorUrl) {
    qrCodeArea = <AuthenticatorQrCode otpAuthUrl={settings.authenticatorUrl} />;
  }

  return (
    <div id="account-settings-update-form">
      <Modal id="account-settings-error-modal" open={showModal} onClose={handleClose}>
        { modalMessage }
      </Modal>
      <FormContainer title="Update Account Settings">
        <Formik
          enableReinitialize
          initialValues={{
            phoneNumber: settings.phoneNumber || '',
            mfaEnabled: settings.mfaEnabled || false,
            mfaContactPreference: settings.mfaContactPreference || 'email'
          }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={4}>
                  <Field
                    id="mfa-enabled"
                    name="mfaEnabled"
                    label="MFA Enabled"
                    component={CheckBoxInput}
                  />
                </Column>
                <Column small={4}>
                  <Field
                    id="phone-number-field"
                    name="phoneNumber"
                    prompt="Phone Number"
                    component={TextInput}
                  />
                </Column>
                <Column small={4}>
                  <Field
                    id="mfa-contact-preference-field"
                    name="mfaContactPreference"
                    prompt="MFA Contact Preference"
                    className="mfa-contact-select"
                    options={contactOptions}
                    onChange={(value) => {
                      if (value === 'authenticator') {
                        setShowQrCode(true);
                      } else {
                        setShowQrCode(false);
                      }
                    }}
                    component={FormSelectDropdown}
                  />
                </Column>
              </Row>
              <Row>
                <Column small={12}>
                  {qrCodeArea}
                </Column>
              </Row>
              <Row className="form-button-container right-align">
                <SubmitButton>Update Account Settings</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
};

export default AccountSettingsForm;
