import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import XLSX from 'xlsx';

import Row from '../../../lib/layout/Row';
import Column from '../../../lib/layout/Column';
import FormContainer from '../../../lib/forms/FormContainer';
import ActionButton from '../../../lib/forms/ActionButton';
import SubmitButton from '../../../lib/forms/SubmitButton';
import FileDragDropUploader from '../../../lib/forms/FileDragDropUploader';
import ExcelSheetInput from '../../../lib/forms/ExcelSheetInput';

import {
  allColumns,
  equipmentDataColumns,
  equipmentDataRows,
  equipmentDataToExcel
} from '../../../lib/data/equipment-workbook-parser';

import {
  useCreateEquipmentListMutation,
  useUpdateEquipmentListMutation,
  useDeleteEquipmentListMutation
} from '../../../lib/ascertis-api';

const EquipmentForm = ({ system, equipmentList }) => {
  const [savedText, setSavedText] = useState('');
  const [createList] = useCreateEquipmentListMutation();
  const [updateList] = useUpdateEquipmentListMutation();
  const [deleteList] = useDeleteEquipmentListMutation();
  const [workbook, setWorkbook] = useState(undefined);

  const onUpload = (fileAsBinaryString) => {
    const workbook = XLSX.read(fileAsBinaryString, { type: 'binary' });
    const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
    setWorkbook(data);
  };

  const handleSubmit = async (formValues, formActions) => {
    if (equipmentList) {
      await updateList({ systemId: system.id, params: formValues }).unwrap()
        .then((payload) => {
          setSavedText('Equipment list successfully uploaded.');
        }).catch((error) => {
          setSavedText('Error updating equipment list.');
        });
    } else {
      await createList({ systemId: system.id, params: formValues }).unwrap()
        .then((payload) => {
          setSavedText('Equipment list successfully uploaded.');
        }).catch((error) => {
          setSavedText('Error updating equipment list.');
        });
    }
  };

  const handleDelete = async () => {
    await deleteList({ systemId: system.id }).unwrap()
      .then((payload) => {
        setSavedText('Equipment list successfully deleted.');
      }).catch((error) => {
        setSavedText('Error deleting equipment list.');
      });
  };

  let initList = [];
  if (equipmentList) {
    initList = equipmentDataToExcel(equipmentList.listData);
  }
  let columns = allColumns();

  let dataArea = (
    <div>Please Upload an Excel Sheet</div>
  );
  if (workbook) {
    columns = equipmentDataColumns(workbook);
    initList = equipmentDataRows(workbook);
  } else if (!initList){
    initList = [];
  }

  const Schema = Yup.object().shape({
    list: Yup.array().of(
      Yup.object().shape({
        ipAddress: Yup.string().required(),
        description: Yup.string().required(),
      })
    )
  });

  let savedTextSection = undefined;
  if (savedText.length > 0) {
    savedTextSection = <div><br/>{savedText}<br/></div>;
  }

  return (
    <div id="update-equipment-form">
      <Row>
        <Column small={12}>
          <FormContainer title="Equipment">
            <Formik
              initialValues={{
                list: initList
              }}
              validationSchema={Schema}
              validateOnChange
              enableReinitialize
              onSubmit={(values, actions) => {
                return handleSubmit(values, actions);
              }}
            >
              {(props) => (
                <Form role="form">
                  <Row>
                    <Column small={12}>
                      <div className="divider" />
                      <div className="section">
                        <Row>
                          <Column small={12}>
                            <Field
                              name="list"
                              component={ExcelSheetInput}
                              columns={columns}
                              onChange={props.setFieldValue}
                            />
                          </Column>
                        </Row>
                      </div>
                    </Column>
                  </Row>
                  <Row>
                    <Column small={12}>
                      {savedTextSection}
                    </Column>
                  </Row>
                  <Row className="form-button-container right-align">
                    <Column small={8}></Column>
                    <Column small={2}>
                      <ActionButton className="btn delete-button grey" onClick={handleDelete} buttonText="Delete Data" />
                    </Column>
                    <Column small={2}>
                      <SubmitButton>Submit</SubmitButton>
                    </Column>
                  </Row>
                </Form>
              )}
            </Formik>
          </FormContainer>
        </Column>
      </Row>
      <Row>
        <Column small={1}></Column>
        <Column small={10}>
          <Row>
            <Column small={6}>
              XLSX Uploads support files with columns labeled "CUI", "IP Address", "Host Name", "Description", "OS Family", "OS Name", "OS Version", "Patch Level", "Manufacturer", "Model", "MAC Address" "Equipment Class", and "Serial Number"
              <br/>
              <a href="/equipment_list_template.xlsx" download>Download Template</a>
            </Column>
            <Column small={6}>
              <FileDragDropUploader onUpload={onUpload} />
            </Column>
          </Row>
        </Column>
        <Column small={1}></Column>
      </Row>
    </div>
  );
};

EquipmentForm.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  equipmentList: PropTypes.object.isRequired
};

export default EquipmentForm;
