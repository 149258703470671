import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const classNames = require('classnames');

import { generateGuid } from './lib/guid-generator';

const PasswordInput = ({
  field,
  form: { touched, errors },
  id,
  prompt = '',
  ...props
}) => {
  const inputId = id || generateGuid();
  const inputValue = field.value || '';

  const isTouched = _.get(touched, field.name);
  const errorMessage = _.get(errors, field.name);
  const validInput = !isTouched || (!errorMessage && isTouched);

  const inputClasses = classNames({
    'text-input': true,
    valid: validInput,
    invalid: !validInput,
  });

  const labelClasses = classNames({
    'text-input-label': true,
    active: (inputValue.length > 0),
  });

  let errorField = null;
  if (isTouched && errorMessage) {
    errorField = (
      <div className="form-error is-visible">
        <span>{errorMessage}</span>
      </div>
    );
  }

  return (
    <div className="input-field" >
      <input id={inputId} type="password" className={inputClasses} {...field} {...props} />
      <label htmlFor={inputId} className={labelClasses}>
        <div className="label-text">
          {prompt}
        </div>
      </label>
      {errorField}
    </div>
  );
};

PasswordInput.propTypes = {
  id: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  }),
  prompt: PropTypes.string,
};

export default PasswordInput;
