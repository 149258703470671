import React from 'react';
import { useParams } from 'react-router-dom';

import Preloader from '../../lib/layout/Preloader';
import Column from '../../lib/layout/Column';
import DocumentsTable from './DocumentsTable';

import {
  useGetSystemQuery,
  useGetDocumentsQuery
} from '../../lib/ascertis-api';

const DocumentsPage = () => {
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: documents } = useGetDocumentsQuery({ systemId });

  if (!system || !documents) {
    return <Preloader />;
  }

  return (
    <div id="documents">
      <Column small={12}>
        <div id="documents-section">
          <DocumentsTable system={system} documents={documents} />
        </div>
      </Column>
    </div>
  );
};

export default DocumentsPage;
