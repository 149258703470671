import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const Column = ({
  small = null,
  medium = null,
  large = null,
  xlarge = null,
  className = '',
  children = null
}) => {
  const colClasses = classNames({
    col: true,
    [`s${small}`]: small,
    [`m${medium}`]: medium,
    [`l${large}`]: large,
    [`xl${xlarge}`]: xlarge,
    [`${className}`]: className
  });

  return (
    <div className={colClasses}>
      { children }
    </div>
  );
};

Column.propTypes = {
  small: PropTypes.number,
  medium: PropTypes.number,
  large: PropTypes.number,
  xlarge: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Column;
