import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const classNames = require('classnames');

const Card = ({
  className = null,
  linkClassName = null,
  linkDisabled = null,
  title = null,
  linkTo = null,
  linkTitle = null,
  children = null
}) => {
  const history = useHistory();
  const cardClasses = classNames({
    card: true,
    [`${className}`]: className
  });

  const linkClasses = classNames({
    cardLink: true,
    [`${linkClassName}`]: linkClassName
  });

  let renderedTitle;
  if (title) {
    renderedTitle = <span className="card-title">{title}</span>;
  }

  let renderedAction;
  // if (linkTo && linkTitle) {
  //   renderedAction = <Link className={linkClasses} to={linkTo}>{linkTitle}</Link>;
  // } else if (linkTitle) {
  //   renderedAction = <Link className={linkClasses} to={"#"}>{linkTitle}</Link>;
  // }

  const handleClick = () => {
    if (linkTo) { history.push(linkTo); }
  };

  if (linkTitle) {
    renderedAction = <a role="link" className={linkClasses} onClick={handleClick}>{linkTitle}</a>;
  }

  return (
    <div className={cardClasses}>
      <div className="card-content">
        { renderedTitle }
        { children }
      </div>
      <div className="card-action">
        { renderedAction }
      </div>
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  title: PropTypes.string,
  linkTo: PropTypes.string,
  linkTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Card;
