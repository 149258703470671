import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  useUpdateControlImplementationMutation
} from '../../lib/ascertis-api';

const sleep = (time) => {
  return new Promise((resolve) => { return setTimeout(resolve, time); });
};

const StandardAnswerForm = ({ system, control, nextControl, implementation = undefined, classification, answerKey }) => {
  const autoAdvanceOnAnswer = false;
  const history = useHistory();
  const [dirty, setDirty] = useState(false)
  const [currentClassification, setCurrentClassification] = useState(control.controlClassification)
  const [modalMessage, setModalMessage] = useState(undefined);
  const [updateControl] = useUpdateControlImplementationMutation();
  const showModal = !_.isEmpty(modalMessage);

  useEffect(() => {
    setDirty(false);
    setCurrentClassification(control.controlClassification);
  }, [control.id]);

  useEffect(() => {
    const bothBlank = _.isEmpty(classification) && _.isEmpty(currentClassification)
    if ((!bothBlank) && (classification !== currentClassification)) {
      setDirty(true);
      setCurrentClassification(classification);
    } else {
      setDirty(false);
    }
  }, [control.id, classification]);

  const handleSubmit = async () => {
    const formValues = {
      controlClassification: classification,
      response: answerKey
    };
    await updateControl({ systemId: system.id, controlId: control.id, params: formValues }).unwrap()
      .then((payload) => {
        setModalMessage(undefined);
        setDirty(false);
        sleep(1200).then(() => {
          if (nextControl && autoAdvanceOnAnswer) {
            history.push(`/systems/${system.id}/controls/${nextControl.key}/implementation`);
          } else if (autoAdvanceOnAnswer) {
            history.push(`/systems/${system.id}`);
          }
        });
      }).catch((error) => {
        setModalMessage(error);
      });
  };

  let buttonText = 'SELECT';
  let color = 'blue-grey darken-1';
  const selected = (implementation.selectedAnswer === answerKey);

  if (selected && dirty) {
    buttonText = 'SAVE CHANGES';
    color = 'green lighten-2';
  } else if (selected) {
    buttonText = 'SELECTED';
    color = 'green darken-1';
  }

  const classes = `answer-option card large ${color}`;
  const names = {
    good_answer: 'GOOD ANSWER',
    better_answer: 'BETTER ANSWER',
    best_answer: 'BEST ANSWER'
  };

  const descriptions = {
    good_answer: implementation.goodAnswer,
    better_answer: implementation.betterAnswer,
    best_answer: implementation.bestAnswer
  };

  return (
    <div className="answer-form-container">
      <div className={classes}>
        <div className="card-content white-text">
          <span className="card-title center-align">{names[answerKey]}</span>
          <p className="description">{descriptions[answerKey]}</p>
        </div>

        <div className="card-action">
          <button id={answerKey} className="waves-effect waves-light btn" onClick={handleSubmit}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

StandardAnswerForm.propTypes = {
  answerKey: PropTypes.string.isRequired,
  implementation: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    customAnswer: PropTypes.string,
    requirementMet: PropTypes.bool
  })
};

export default StandardAnswerForm;
