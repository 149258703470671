import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { generateGuid } from './lib/guid-generator';

import RadioSelect from './RadioSelect';

const RadioSelectInput = ({
  field,
  form: { touched, errors },
  id,
  prompt = undefined,
  options,
  onChange = () => {}
}) => {
  const inputId = id || generateGuid();
  const isTouched = _.get(touched, field.name);
  const errorMessage = _.get(errors, field.name);
  const validInput = !isTouched || (!errorMessage && isTouched);

  let errorField = null;
  if (isTouched && errorMessage) {
    errorField = (
      <div className="form-error is-visible">
        <span>{errorMessage}</span>
      </div>
    );
  }

  const handleSelect = (value) => {
    onChange(field.name, value);
  };

  return (
    <div className="input-checkbox">
      <RadioSelect
        id={inputId}
        label={prompt}
        options={options}
        selected={field.value}
        valid={validInput}
        onSelect={handleSelect}
      />
      {errorField}
    </div>
  );
};

RadioSelectInput.propTypes = {
  id: PropTypes.string,
  field: PropTypes.shape({
    value: PropTypes.any
  }),
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  }),
  prompt: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default RadioSelectInput;
