import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
const moment = require('moment');

import Modal from '../../lib/layout/Modal';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import DateInput from '../../lib/forms/DateInput';
import TextAreaInput from '../../lib/forms/TextAreaInput';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useUpdateActionItemMutation } from '../../lib/ascertis-api';

const ActionItemForm = ({ system, actionItem }) => {
  const [updateItem] = useUpdateActionItemMutation();
  const [modalMessage, setModalMessage] = useState(undefined);
  const showModal = !_.isEmpty(modalMessage);

  const handleSubmit = async (formValues) => {
    await updateItem({ systemId: system.id, actionItemId: actionItem.id, params: formValues }).unwrap()
      .then((payload) => {
        // setModalMessage('Action Item updated.');
        // Per Steven Senz, we do not want to show a success message.
        setModalMessage(undefined);
      }).catch((error) => {
        setModalMessage(error);
      });
  };

  const Schema = Yup.object().shape({
    remediation: Yup.string().required(),
    resourcesNeeded: Yup.string().required(),
    projectedCompletion: Yup.string().required()
  });
  const currentDate = moment().format('YYYY-MM-DD');

  const handleClose = () => setModalMessage(undefined);

  return (
    <div id={`update-action-item-${actionItem.id}`} className='action-item'>
      <Modal id="notification-modal" open={showModal} onClose={handleClose}>
        { modalMessage }
      </Modal>
      <Formik
        initialValues={{
          remediation: actionItem.remediation || '',
          resourcesNeeded: actionItem.resourcesNeeded || '',
          projectedCompletion: actionItem.projectedCompletion || '',
          actualCompletion: actionItem.actualCompletion || ''
        }}
        validationSchema={Schema}
        enableReinitialize
        onSubmit={(values, actions) => {
          return handleSubmit(values, actions);
        }}
      >
        {props => (
          <Form role="form">
            <Row>
              <h6>Plan of Action Details</h6>
              <Column small={6}>
                <Field
                  id={`remediation-${actionItem.id}`}
                  name="remediation"
                  prompt="Remediation"
                  component={TextAreaInput}
                />
              </Column>
              <Column small={6}>
                <Field
                  id={`resourcesNeeded-${actionItem.id}`}
                  name="resourcesNeeded"
                  prompt="Resources Needed"
                  component={TextAreaInput}
                />
              </Column>
            </Row>

            <Row>
              <Column small={6}>
                <Field
                  id={`projectedCompletion-${actionItem.id}`}
                  name="projectedCompletion"
                  prompt="Projected Completion"
                  component={DateInput}
                />
              </Column>
              <Column small={6}>
                <Field
                  id={`actualCompletion-${actionItem.id}`}
                  name="actualCompletion"
                  prompt="Actual Completion"
                  max={currentDate}
                  component={DateInput}
                />
              </Column>
            </Row>

            <Row className="form-button-container right-align">
              <SubmitButton>Submit</SubmitButton>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ActionItemForm.propTypes = {
  actionItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    controlIdNumber: PropTypes.string.isRequired,
    controlName: PropTypes.string.isRequired,
    requirementDescription: PropTypes.string.isRequired,
    remediation: PropTypes.string,
    projectedCompletion: PropTypes.string,
    resourcesNeeded: PropTypes.string
  }).isRequired
};

export default ActionItemForm;
