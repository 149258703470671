import React from 'react';
import PropTypes from 'prop-types';

import UpdateUserForm from './UpdateUserForm';

const SystemOwnerForm = ({ system, user }) => {
  const tooltip = "This is the person that determines the use of the system and pays for the system upgrades, and additions. This person is NOT responsible for the security of the system.";

  return (
    <div id="system-owner-container">
      <UpdateUserForm
        system={system}
        user={user}
        title="System Owner"
        tooltip={tooltip}
        roleName="SystemOwner"
      />
    </div>
  );
};

SystemOwnerForm.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  })
};

export default SystemOwnerForm;
