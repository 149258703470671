import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Row from '../../lib/layout/Row';
import SelectDropdown from '../../lib/forms/SelectDropdown';
import AtoReportForm from './AtoReportForm';
import CmmcReportForm from './CmmcReportForm';
import PoamReportForm from './PoamReportForm';
import RarReportForm from './RarReportForm';
import SarReportForm from './SarReportForm';
import SspReportForm from './SspReportForm';
import SteReportForm from './SteReportForm';
import SprsReportForm from './SprsReportForm';

const ReportFormSelector = ({ system, user }) => {
  const roles = user.roles || [];
  const [selectedForm, setSelectedForm] = useState('');
  const handleSelect = (newValue) => {
    setSelectedForm(newValue);
  };

  const canGenerateCMMC = _.includes(roles, 'Certifier');
  let options = [
    { label: 'ATO Report', value: 'ato' },
    { label: 'POAM Report', value: 'poam' },
    { label: 'RAR Report', value: 'rar' },
    { label: 'SAR Report', value: 'sar' },
    { label: 'SSP Report', value: 'ssp' },
    { label: 'STE Report', value: 'ste' },
    { label: 'SPRS Report', value: 'sprs' }
  ];

  if (canGenerateCMMC) {
    options = [
      { label: 'ATO Report', value: 'ato' },
      { label: 'CMMC Report', value: 'cmmc' },
      { label: 'POAM Report', value: 'poam' },
      { label: 'RAR Report', value: 'rar' },
      { label: 'SAR Report', value: 'sar' },
      { label: 'SSP Report', value: 'ssp' },
      { label: 'STE Report', value: 'ste' },
      { label: 'SPRS Report', value: 'sprs' }
    ];
  }

  const validOptions = _.filter(options, (option) => {
    return _.includes(system.availableReports, option.value);
  });

  const forms = {
    ato: AtoReportForm,
    cmmc: CmmcReportForm,
    poam: PoamReportForm,
    rar: RarReportForm,
    sar: SarReportForm,
    ssp: SspReportForm,
    ste: SteReportForm,
    sprs: SprsReportForm
  };

  let formArea = (
    <div id="form-component-not-selected" >
      <h6>Please select the report that you'd like to generate from the drop-down above.</h6>
      <p>You will then be prompted to add in any other details required for this report.</p>
    </div>
  );

  if (forms[selectedForm]) {
    const FormContainer = forms[selectedForm];
    formArea = <FormContainer system={system} />;
  }

  return (
    <div id="report-form-selector">
      <Row>
        <div className="header">
          <h4>Generate New Reports</h4>
        </div>
      </Row>
      <Row>
        <SelectDropdown
          id="report-select"
          prompt="Report Selector"
          value={selectedForm}
          options={validOptions}
          onSelect={handleSelect}
        />
      </Row>
      <Row>
        {formArea}
      </Row>
    </div>
  );
};

ReportFormSelector.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};

export default ReportFormSelector;
