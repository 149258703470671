import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import TextAreaInput from '../../lib/forms/TextAreaInput';
import SubmitButton from '../../lib/forms/SubmitButton';

import {
  useUpdateControlImplementationMutation
} from '../../lib/ascertis-api';

const sleep = (time) => {
  return new Promise((resolve) => { return setTimeout(resolve, time); });
}

const NotApplicableAnswerForm = ({ system, control, nextControl, implementation }) => {
  const autoAdvanceOnAnswer = false;
  const history = useHistory();
  const [modalMessage, setModalMessage] = useState(undefined);
  const [updateControl] = useUpdateControlImplementationMutation();
  const showModal = !_.isEmpty(modalMessage);

  const handleSubmit = async (formValues) => {
    formValues['response'] = 'not_applicable';
    await updateControl({ systemId: system.id, controlId: control.id, params: formValues }).unwrap()
      .then((payload) => {
        setModalMessage(undefined);
        sleep(1200).then(() => {
          if (nextControl && autoAdvanceOnAnswer) {
            history.push(`/systems/${system.id}/controls/${nextControl.key}/implementation`);
          } else if (autoAdvanceOnAnswer) {
            history.push(`/systems/${system.id}`);
          }
        });
      }).catch((error) => {
        setModalMessage(error);
      });
  };

  const customAnswerSelected = (implementation.selectedAnswer === 'not_applicable');
  const cardColor = customAnswerSelected ? 'green darken-1' : 'blue-grey darken-1';
  const textColor = 'white-text';
  let buttonText = customAnswerSelected ? 'SELECTED' : 'SELECT';

  const Schema = Yup.object().shape({
    explanation: Yup.string().required()
  });

  return (
    <div id="not-applicable-answer-form" className="answer-option">
      <FormContainer title="Reason Not Applicable" contentClasses={textColor} cardClasses={cardColor}>
        <Formik
          initialValues={{ explanation: implementation.customAnswer || '' }}
          enableReinitialize
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            const params = {
              description: values.explanation
            };
            return handleSubmit(params, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={12}>
                  <Field
                    name="explanation"
                    prompt="Explanation"
                    component={TextAreaInput}
                  />
                </Column>
              </Row>

              <Row className="form-button-container right-align">
                <SubmitButton>{buttonText}</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
}

NotApplicableAnswerForm.propTypes = {
  implementation: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    selectedAnswer: PropTypes.string,
    customAnswer: PropTypes.string,
    requirementMet: PropTypes.bool
  }).isRequired
};

export default NotApplicableAnswerForm;
