import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Formik, Form, Field, FieldArray
} from 'formik';

import _ from 'lodash';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import CheckBoxInput from '../../lib/forms/CheckBoxInput';
import SubmitButton from '../../lib/forms/SubmitButton';
import Modal from '../../lib/layout/Modal';

import {
  useBulkUpdateControlRequirementsMutation
} from '../../lib/ascertis-api';

const sleep = (time) => {
  return new Promise((resolve) => { return setTimeout(resolve, time); });
};

const RequirementForm = ({ system, control, nextControl, requirements = [] }) => {
  const autoAdvanceOnAnswer = false;
  const history = useHistory();
  const [modalMessage, setModalMessage] = useState(undefined);
  const [updateRequirements] = useBulkUpdateControlRequirementsMutation();
  const showModal = !_.isEmpty(modalMessage);

  const handleModalClose = () => setModalMessage(undefined);

  const handleSubmit = async (formValues) => {
    const selectedIds = _.keys(_.pickBy(formValues, (value, reqId) => !!value));
    const requestDetails = _.map(requirements, (requirement) => {
      const requirementMet = _.includes(selectedIds, requirement.id);
      return {
        url: `api/v1/systems/${system.id}/controls/${control.id}/requirements/${requirement.id}`,
        method: 'PUT',
        params: { selected: true, requirementMet }
      };
    });

    const numReqs = requestDetails.length;
    const numReqsMet = requestDetails.filter((req) => req.params.requirementMet).length;
    const submittingAllRequirementsUnmet = numReqs !== numReqsMet;

    if (submittingAllRequirementsUnmet && control.cmmcScorecardComment) {
      setModalMessage(control.cmmcScorecardComment);
    }

    await updateRequirements({ params: requestDetails }).unwrap()
      .then((payload) => {
        setModalMessage(undefined);
        sleep(1200).then(() => {
          if (nextControl && autoAdvanceOnAnswer) {
            history.push(`/systems/${system.id}/controls/${nextControl.key}/requirements`);
          } else if (autoAdvanceOnAnswer) {
            history.push(`/systems/${system.id}`);
          }
        });
      }).catch((error) => {
        setModalMessage(error);
      });
  };

  const orderedRequirements = _.sortBy(requirements, (requirement) => { return requirement.orderNumber; });
  const metRequirements = _.filter(orderedRequirements, (requirement) => { return requirement.requirementMet; });
  const requirementIds = _.map(metRequirements, (requirement) => { return requirement.id; });
  const requirementsNA = _.every(requirements, (requirement) => { return requirement.notApplicable });
  const title = requirementsNA ? "Requirements (Not Applicable)" : "Requirements";

  let initialValues = {};
  _.forEach(orderedRequirements, (requirement) => {
    const isSelected = _.includes(requirementIds, requirement.id);
    initialValues[requirement.id] = isSelected;
  });

  return (
    <div id="requirements-form">
      <FormContainer title={title}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              {_.map(orderedRequirements, (requirement, index) => {
                return (
                  <Row key={`input-${requirement.id}`}>
                    <Column small={12}>
                      <Field
                        id={requirement.id}
                        name={requirement.id}
                        disabled={requirement.status === 'not_applicable'}
                        label={`${index + 1} - ${requirement.description}`}
                        component={CheckBoxInput}
                      />
                    </Column>
                  </Row>
                );
              })}

              <Row className="form-button-container right-align">
                <SubmitButton>Save</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
      <Modal id="requirement-warning-modal" open={showModal} onClose={handleModalClose}>
        { modalMessage }
      </Modal>
    </div>
  );
};

RequirementForm.propTypes = {
  requirements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    requirementMet: PropTypes.bool
  }))
};

export default RequirementForm;
