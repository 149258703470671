import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import CapabilitiesTableRow from './CapabilitiesTableRow';

const CapabilitiesTable = ({ capabilities, system, controls }) => {
  const showLevelOne = system.cmmcLevel >= 1;
  const showLevelTwo = system.cmmcLevel >= 2;
  const showLevelThree = system.cmmcLevel >= 3;

  const capabilitiesRows = _.map(capabilities, (capability, id) => {
    return (
        <CapabilitiesTableRow
            key={`input-${id}`}
            system={system}
            controls={controls}
            capability={capability}
        />
    );
  });

  const levelOneHeader = showLevelOne ? (<th>CMMC Level One</th>) : undefined;
  const levelTwoHeader = showLevelTwo ? (<th>CMMC Level Two</th>) : undefined;
  const levelThreeHeader = showLevelThree ? (<th>CMMC Level Three</th>) : undefined;

  return (
      <table id="capabilities-table" className="striped responsive-table">
        <thead className="header">
        <tr>
          <th>Domain</th>
          <th>Capability</th>
          { levelOneHeader }
          { levelTwoHeader }
          { levelThreeHeader }
        </tr>
        </thead>
        <tbody>
        {capabilitiesRows}
        </tbody>
      </table>
  );
};

CapabilitiesTable.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired,
    cmmcLevel: PropTypes.number.isRequired
  }).isRequired,
  controls: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  capabilities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    cmmcLevelOnePass: PropTypes.bool,
    cmmcLevelTwoPass: PropTypes.bool,
    cmmcLevelThreePass: PropTypes.bool
  })).isRequired
};

export default CapabilitiesTable;
