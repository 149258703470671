import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const Page = ({ title = '', className = '', children = null }) => {
  const pageClasses = classNames({
    page: true,
    [`${className}`]: className
  });

  return (
    <div className={pageClasses}>
      {children}
    </div>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Page;
