import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import SelectDropdown from '../../lib/forms/SelectDropdown';

import { useUpdateThreatMutation } from '../../lib/ascertis-api';

const ThreatsTableRow = ({ threat, controls, system }) => {
  const [selected, setSelected] = useState(threat.impactSeverity);
  const [updateItem] = useUpdateThreatMutation();

  const handleSelect = async (value) => {
    setSelected(value);
    await updateItem({ systemId: system.id, threatId: threat.id, params: { impactSeverity: value } }).unwrap()
      .then((payload) => {
        // setModalMessage('Action Item updated.');
      }).catch((error) => {
        // setModalMessage(error);
      });
  };

  let controlsLinks = '';
  if (threat.controlIdNumbers) {
    controlsLinks = _.map(threat.controlIdNumbers, (id) => {
      const controlId = id.toUpperCase();
      const control = _.find(controls, ['controlIdNumber', controlId]);
      let link = undefined;

      if (control) {
        link = <Link to={`/systems/${system.id}/controls/${control.id}/requirements`}>{control.controlIdNumber}</Link>;

        const controlFilledOut = control.implementationSelected && control.requirementsSelected;
        const controlFullySatisfied = controlFilledOut && (control.allRequirementsMet || control.notApplicable);
        const controlPartiallySatisfied = controlFilledOut && !controlFullySatisfied;
        let chipClasses = 'chip';
        if (controlFullySatisfied) {
          chipClasses = 'chip green lighten-3';
        } else if (!controlFilledOut) {
          chipClasses = 'chip red lighten-3';
        }
        return (
          <div key={id} className={chipClasses}>{link}</div>
        );
      } else {
        return (
          <div key={id} className="chip">{controlId}</div>
        );
      }
    });
  }

  const options = [
    { label: 'low', value: 'low' },
    { label: 'moderate', value: 'moderate' },
    { label: 'high', value: 'high' }
  ];

  let rowClasses = `threat-table-row selected threat-${threat.id}`;
  let selectClasses = 'impact-select impact-selected';
  if (!threat.calculatedRisk && (threat.optionalInput || threat.requiredInput)) {
    rowClasses = `threat-table-row unselected threat-${threat.id}`;
    selectClasses = 'impact-select impact-unselected';
  }

  let inputField = '';
  if (threat.optionalInput || threat.requiredInput) {
    inputField = (
      <SelectDropdown
        id={`impactSeveritySelector-${threat.id}`}
        prompt="Severity"
        value={selected || ""}
        options={options}
        onSelect={handleSelect}
      />
    );
  }

  return (
    <tr key={`input-${threat.id}`} className={rowClasses}>
      <td>{threat.threatType}</td>
      <td>{threat.threatSource}</td>
      <td>{controlsLinks}</td>
      <td>{threat.likelihoodOfOccurence}</td>
      <td className={selectClasses}>
        <div className="input-field">
          {inputField}
        </div>
      </td>
      <td>{threat.calculatedRisk}</td>
    </tr>
  );
};

ThreatsTableRow.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  controls: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  threat: PropTypes.shape({
    id: PropTypes.string.isRequired,
    threatType: PropTypes.string.isRequired,
    threatSource: PropTypes.string.isRequired,
    calculatedRisk: PropTypes.string,
    impactSeverity: PropTypes.string,
    likelihoodOfOccurence: PropTypes.string
  }).isRequired
};

export default ThreatsTableRow;
