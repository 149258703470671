import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Modal from '../../lib/layout/Modal';
import Row from '../../lib/layout/Row';
import FormContainer from '../../lib/forms/FormContainer';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useCreateReportMutation } from '../../lib/ascertis-api';

const Schema = Yup.object().shape({
});

const SspReportForm = ({ system }) => {
  const [createReport] = useCreateReportMutation();
  const [modalMessage, setModalMessage] = useState(undefined);
  const showModal = !_.isEmpty(modalMessage);

  const handleSubmit = async (formValues) => {
    const params = { reportType: 'ssp', options: formValues };
    await createReport({ systemId: system.id, params }).unwrap()
      .then((payload) => {
        setModalMessage('Report created.');
      }).catch((error) => {
        setModalMessage(error);
      });
  };

  const handleClose = () => setModalMessage(undefined);

  return (
    <div id="new-ssp-report-form">
      <FormContainer title="Generate New SSP Report">
        <Modal id="report-modal" open={showModal} onClose={handleClose}>
          { modalMessage }
        </Modal>
        <Formik
          initialValues={{ }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row className="form-button-container right-align">
                <SubmitButton>Submit</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
}

SspReportForm.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  onSubmit: PropTypes.func
};

export default SspReportForm;
