import React from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { AbilityContext } from '../lib/abilities';
// import { useFetchUserAgreement} from './lib/use-fetch-user-agreement';
// import { useLogoutOnClose } from './lib/use-logout-on-close';
// import { useUserAuthLogout } from './lib/use-user-auth-logout';

import AuthDefaultLayout from '../Layout/AuthDefaultLayout';
import ImplementationLayout from '../Layout/ImplementationLayout';
import RequirementsLayout from '../Layout/RequirementsLayout';
import SystemsPage from '../Systems/SystemsPage';
import SystemPage from '../Systems/SystemPage';
import SystemDescriptionPage from '../SystemDescription/SystemDescriptionPage';
import ActionPlanPage from '../ActionPlan/ActionPlanPage';
import ActionPlanUploadPage from '../ActionPlan/ActionPlanUploadPage';
import CapabilitiesPage from '../Capabilities/CapabilitiesPage';
import ControlsRedirectPage from '../Controls/ControlsRedirectPage';
import ImplementationPage from '../Implementations/ImplementationPage';
import RequirementsPage from '../Requirements/RequirementsPage';
import ThreatsPage from '../Threats/ThreatsPage';
import CertificationPage from '../Certification/CertificationPage';
import SolutionPartnersPage from '../SolutionPartners/SolutionPartnersPage';
import DocumentsPage from '../Documents/DocumentsPage';
import ReportsPage from '../Reports/ReportsPage';
import UserAgreementExpired from '../Error/UserAgreementExpired';
import AccountManagementPage from '../User/AccountManagementPage';

import { useGetUserAgreementQuery } from '../../lib/ascertis-api';

import NotFound from '../Error/NotFound';

const AuthenticatedRouter = () => {
  const { data: userAgreement } = useGetUserAgreementQuery();
  let agreementExpired = false;
  if (userAgreement && userAgreement.status == 'expired') {
    agreementExpired = true;
  }

  if (agreementExpired) {
    return (
      <AuthDefaultLayout>
        <UserAgreementExpired />
      </AuthDefaultLayout>
    );
  }

  // const fifteenMinutes = 900; // 15 minutes is 900 seconds
  // useLogoutOnClose();
  // useUserAuthLogout();
  // THIS WAS ALREADY COMMENTED OUT
  // useReduxActionTimeout(fifteenMinutes);

  const permissions = {};
  return (
    <AbilityContext.Provider value={permissions}>
      <Switch>
        <Route exact path="/">
          <AuthDefaultLayout>
            <SystemsPage />
          </AuthDefaultLayout>
        </Route>

        <Route exact path="/home">
          <AuthDefaultLayout>
            <SystemsPage />
          </AuthDefaultLayout>
        </Route>

        <Route exact path="/systems">
          <AuthDefaultLayout>
            <SystemsPage />
          </AuthDefaultLayout>
        </Route>

        <Route exact path="/systems/:systemId">
          <AuthDefaultLayout>
            <SystemPage />
          </AuthDefaultLayout>
        </Route>

        <Route exact path="/systems/:systemId/description">
          <AuthDefaultLayout>
            <SystemDescriptionPage />
          </AuthDefaultLayout>
        </Route>

        <Route exact path="/systems/:systemId/controls">
          <ControlsRedirectPage suffix="implementation" />
        </Route>

        <Route exact path="/systems/:systemId/requirements">
          <ControlsRedirectPage suffix="requirements" />
        </Route>

        <Route exact path="/systems/:systemId/controls/:controlId">
          <ImplementationLayout>
            <ImplementationPage />
          </ImplementationLayout>
        </Route>

        <Route exact path="/systems/:systemId/controls/:controlId/implementation">
          <ImplementationLayout>
            <ImplementationPage />
          </ImplementationLayout>
        </Route>

        <Route exact path="/systems/:systemId/controls/:controlId/requirements">
          <RequirementsLayout>
            <RequirementsPage />
          </RequirementsLayout>
        </Route>

        <Route exact path="/systems/:systemId/action_plan">
          <AuthDefaultLayout>
            <ActionPlanPage />
          </AuthDefaultLayout>
        </Route>

        <Route exact path="/systems/:systemId/action_plan/upload">
          <AuthDefaultLayout>
            <ActionPlanUploadPage />
          </AuthDefaultLayout>
        </Route>

        <Route exact path="/systems/:systemId/threats">
          <AuthDefaultLayout>
            <ThreatsPage />
          </AuthDefaultLayout>
        </Route>

        <Route exact path="/systems/:systemId/capabilities">
          <AuthDefaultLayout>
            <CapabilitiesPage />
          </AuthDefaultLayout>
        </Route>

        <Route exact path="/systems/:systemId/certification">
          <AuthDefaultLayout>
            <CertificationPage />
          </AuthDefaultLayout>
        </Route>

        <Route exact path="/systems/:systemId/documents">
          <AuthDefaultLayout>
            <DocumentsPage />
          </AuthDefaultLayout>
        </Route>

        <Route exact path="/solution_partners">
          <AuthDefaultLayout>
            <SolutionPartnersPage />
          </AuthDefaultLayout>
        </Route>

        <Route exact path="/systems/:systemId/reports">
          <AuthDefaultLayout>
            <ReportsPage />
          </AuthDefaultLayout>
        </Route>

        <Route exact path="/account/edit">
          <AuthDefaultLayout>
            <AccountManagementPage />
          </AuthDefaultLayout>
        </Route>

        <Route>
          <AuthDefaultLayout>
            <NotFound />
          </AuthDefaultLayout>
        </Route>
      </Switch>
    </AbilityContext.Provider>
  );
};

AuthenticatedRouter.propTypes = {};

export default withRouter(AuthenticatedRouter);
