import React from 'react';
import { useParams } from 'react-router-dom';

import Page from '../../lib/layout/Page';
import Column from '../../lib/layout/Column';
import Tabs from '../../lib/layout/Tabs';

import GeneralInformationPage from './GeneralInformationPage';
import LogoPage from './LogoPage';
import NetworkDiagramPage from './NetworkDiagramPage';
import UserManagementPage from './UserManagementPage';
import EquipmentManager from './EquipmentManager';
import SoftwareManager from './SoftwareManager';

import { useGetSystemQuery } from '../../lib/ascertis-api';

const SystemDescriptionPage = () => {
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });

  if (!system) {
    return <div>Loading</div>;
  }

  const titles = ['General', 'Logo', 'Network Diagram', 'Users', 'Equipment', 'Software'];
  return (
    <div id="description" className="app-container">
      <main>
        <Page>
          <Column small={12}>
            <Tabs titles={titles}>
              <GeneralInformationPage key="general" />
              <LogoPage key="logo" />
              <NetworkDiagramPage key="network-diagram" />
              <UserManagementPage key="users" />
              <EquipmentManager key="equipment" />
              <SoftwareManager key="software" />
            </Tabs>
          </Column>
        </Page>
      </main>
    </div>
  );
};

SystemDescriptionPage.propTypes = {};

export default SystemDescriptionPage;
