import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useDropdownEffect } from './lib/use-dropdown-effect';

const classNames = require('classnames');

const FormSelectDropdown = ({
  field,
  form: { touched, errors },
  id,
  options = [],
  prompt = '',
  className,
  onChange = () => {}
}) => {
  const inputId = id;
  const name = field.name;
  const value = field.value;
  useDropdownEffect(inputId, value, options);

  const [selected, setSelected] = useState(value);
  useEffect(() => {
    if (value !== selected) {
      onChange(value);
    }
    setSelected(value);
  }, [value]);

  const divClasses = classNames({
    'input-field': true,
    'form-select-dropdown-div': true,
    [`${className}`]: className
  });

  // const handleChange = (evt) => {
  //   console.log('HANDLING CHANGE in FormSelectDropdown');
  //   onChange(evt);
  // }

  const optionsHtml = _.map(options, (option) => {
    return (
      <option key={option.value} id={`${inputId}-option-${option.value}`} value={option.value}>{option.label}</option>
    );
  });

  let errorField = null;
  const isTouched = _.get(touched, field.name);
  const errorMessage = _.get(errors, field.name);

  if (isTouched && errorMessage) {
    errorField = (
      <div className="form-error is-visible">
        <span>{errorMessage}</span>
      </div>
    );
  }

  return (
    <div className={divClasses}>
      <select id={inputId} {...field}>
        <option id={`${inputId}-option-disabled`} value="" disabled>Select</option>
        {optionsHtml}
      </select>

      <label htmlFor={inputId}>{prompt}</label>
      {errorField}
    </div>
  );
};

FormSelectDropdown.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }),
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  }),
  prompt: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func
};

export default FormSelectDropdown;
