import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { generateGuid } from './lib/guid-generator';

const classNames = require('classnames');

const CheckBoxInput = ({
  field,
  form: { touched, errors },
  id,
  label,
  disabled = false,
  onChange = () => {}
}) => {
  const inputId = id || generateGuid();
  const isTouched = _.get(touched, field.name);
  const errorMessage = _.get(errors, field.name);
  const validInput = !isTouched || (!errorMessage && isTouched);

  const inputClasses = classNames({
    'checkbox-input': true,
    'filled-in': true,
    valid: validInput,
    invalid: !validInput
  });
  const isChecked = !!field.value;

  const labelClasses = classNames({
    'checkbox-input-label': true
  });

  let errorField = null;
  if (isTouched && errorMessage) {
    errorField = (
      <div className="form-error is-visible">
        <span>{errorMessage}</span>
      </div>
    );
  }
  return (
    <div className="input-checkbox">
      <label htmlFor={inputId}>
        <input
          disabled={disabled}
          name={inputId}
          id={inputId}
          type="checkbox"
          data-testid={`checkbox-${inputId}`}
          className={inputClasses}
          checked={isChecked}
          {...field}
        />
        <span className={labelClasses}>{label}</span>
      </label>
      {errorField}
    </div>
  );
};

CheckBoxInput.propTypes = {
  id: PropTypes.string,
  field: PropTypes.shape({
    value: PropTypes.any
  }),
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  }),
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

export default CheckBoxInput;
