import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const CapabilitySummaryCell = ({ controls, system, pass = false}) => {
  let links = '';
  if (controls) {
    links = _.map(controls, (control) => {
      const link = <Link to={`/systems/${system.id}/controls/${control.id}/requirements`} >{control.controlIdNumber}</Link>;

      const controlFilledOut = control.implementationSelected && control.requirementsSelected;
      const controlFullySatisfied = controlFilledOut && (control.allRequirementsMet || control.notApplicable);
      const controlPartiallySatisfied = controlFilledOut && !controlFullySatisfied;
      let chipClasses = 'chip';
      if (controlFullySatisfied) {
        chipClasses = 'chip green lighten-3';
      } else if (!controlFilledOut) {
        chipClasses = 'chip red lighten-3';
      }
      return (
        <div key={control.id} className={chipClasses}>{link}</div>
      );
    });
  }

  const levelPass = pass ? "LEVEL PASSED" : "LEVEL NOT PASSED";

  return (
    <div>
      <div>{levelPass}</div>
      <div>{links}</div>
    </div>
  );
};

CapabilitySummaryCell.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  controls: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired
};

export default CapabilitySummaryCell;
