import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

var moment = require('moment');

import Row from '../../lib/layout/Row';

const ReportsList = ({ reports = [] }) => {
  const listItems = _.map(reports, (report) => {
    const time = moment(report.generatedAt).format("dddd, MMMM Do YYYY, h:mm:ss a");
    return (
      <tr key={report.id}>
        <td>{report.reportType}</td>
        <td>{time}</td>
        <td><a href={report.url} target="_blank">View</a></td>
      </tr>
    );
  });

  return (
    <div id="report-list">
      <Row>
        <div className="header">
          <h4>Existing Reports</h4>
        </div>
      </Row>
      <Row>
        <table className="bordered highlight responsive-table">
          <thead>
            <tr>
              <th>Report Type</th>
              <th>Generated At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            { listItems }
          </tbody>
        </table>
      </Row>
    </div>
  );
};

ReportsList.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    generatedAt: PropTypes.string,
    url: PropTypes.string
  }))
};

export default ReportsList;
