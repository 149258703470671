import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import _ from 'lodash';

import * as Yup from 'yup';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import TextAreaInput from '../../lib/forms/TextAreaInput';
import MultiSelectInput from '../../lib/forms/MultiSelectInput';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useUpdateRequirementNoteMutation } from '../../lib/ascertis-api';

const EditNoteForm = ({ system, control, requirements, note, visible = true, onSubmit = () => {} }) => {
  const [updateNote] = useUpdateRequirementNoteMutation();

  const orderedRequirements = _.sortBy(requirements, (requirement) => { return requirement.orderNumber });
  const requirementOptions= _.map(orderedRequirements, (requirement, id) => {
    return { value: requirement.id, label: `Requirement ${id + 1}` };
  });
  const handleSubmit = async (formValues) => {
    await updateNote({ systemId: system.id,
      controlId: control.id, noteId: note.id, params: formValues }).unwrap()
      .then((payload) => {
        onSubmit();
      }).catch((error) => {
      });
  };

  const Schema = Yup.object().shape({
    body: Yup.string().required()
  });

  return (
    <div id="edit-note-form">
      <Formik
        initialValues={{
          body: note.body,
          requirements: note.requirements
        }}
        enableReinitialize
        validationSchema={Schema}
        onSubmit={(values, actions) => {
          return handleSubmit(values, actions).then(() => {
            actions.resetForm();
          });
        }}
      >
        {props => (
          <Form role="form">
            <Row>
              <Column small={8}>
                <Field name="body" prompt="Note" component={TextAreaInput} />
              </Column>

              <Column small={4}>
                <Field
                  id="requirements"
                  name="requirements"
                  prompt="Requirements"
                  options={requirementOptions}
                  hidden={!visible}
                  component={MultiSelectInput}
                />
              </Column>
            </Row>

            <Row className="form-button-container right-align">
              <SubmitButton>Save</SubmitButton>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

EditNoteForm.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  control: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  requirements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  note: PropTypes.shape({
    id: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    requirements: PropTypes.array.isRequired
  }).isRequired,
  visible: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default EditNoteForm;
