import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import TextAreaInput from '../../lib/forms/TextAreaInput';
import MultiSelectInput from '../../lib/forms/MultiSelectInput';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useCreateRequirementNoteMutation } from '../../lib/ascertis-api';

const NoteForm = ({ system, control, requirements, visible = true, onSubmit = () => {} }) => {
  const [createNote] = useCreateRequirementNoteMutation();

  const handleSubmit = async (formValues) => {
    const params = {
      scope: 'requirement',
      body: formValues.body,
      requirements: formValues.requirements
    }
    await createNote({ systemId: system.id, controlId: control.id, params }).unwrap()
      .then((payload) => {
        onSubmit();
      }).catch((error) => {
        onSubmit();
      });
  };

  const Schema = Yup.object().shape({
    body: Yup.string().required(),
    requirements: Yup.array().min(1)
  });

  const orderedRequirements = _.sortBy(requirements, (requirement) => { return requirement.orderNumber });
  const requirementOptions= _.map(orderedRequirements, (requirement, id) => {
    return { value: requirement.id, label: `Requirement ${id + 1}` };
  });

  // Note:  We are adding the systemId and controlId to the initialValues so that we force
  // reinitialization
  return (
    <div id="new-requirement-note-form">
      <FormContainer>
        <Formik
          initialValues={{
            body: '',
            systemId: system.id,
            controlId: control.id,
            requirements: []
          }}
          enableReinitialize
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions).then(() => {
              actions.resetForm();
            });
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={8}>
                  <Field name="body" prompt="Note" component={TextAreaInput} />
                </Column>

                <Column small={4}>
                  <Field
                    id="requirements"
                    name="requirements"
                    prompt="Requirements"
                    options={requirementOptions}
                    hidden={!visible}
                    component={MultiSelectInput}
                  />
                </Column>
              </Row>

              <Row className="form-button-container right-align">
                <SubmitButton>Save</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
}

NoteForm.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  control: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  requirements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  visible: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default NoteForm;
