import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const Section = ({ title = null, className = '', children = null }) => {
  const sectionClasses = classNames({
    section: true,
    'with-title': title,
    [`${className}`]: className
  });

  let titleDisplay;
  if (title) {
    titleDisplay = <h5 className="section-title">{title}</h5>;
  }

  return (
    <div className={sectionClasses}>
      <div className="container">
        {titleDisplay}
        <div className="section-content">
          {children}
        </div>
      </div>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Section;
