import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  useGetDocumentControlsQuery,
  useGetDocumentRequirementsQuery
} from '../../lib/ascertis-api';

const DocumentsTableRow = ({ system, document }) => {
  const { data: controls } = useGetDocumentControlsQuery({
    systemId: system.id, documentId: document.id
  });
  const { data: requirements } = useGetDocumentRequirementsQuery({
    systemId: system.id, documentId: document.id
  });

  let currentControlIds = [];
  let currentRequirementIds = [];

  if (controls && requirements) {
    currentControlIds = _.map(controls, (control) => control.id);
    currentRequirementIds = _.map(requirements, (requirement) => requirement.id);
  }

  const rowClasses = `document-table-row document-${document.id}`;

  return (
    <tr key={`input-${document.id}`} className={rowClasses}>
      <td>
        <a href={document.url} target="_blank">{document.name}</a>
      </td>
      <td>
        { currentControlIds.join(', ') }
      </td>
      <td>
        { currentRequirementIds.join(', ') }
      </td>
    </tr>
  );
};

DocumentsTableRow.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

export default DocumentsTableRow;
