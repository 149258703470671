import React from 'react';
import { useParams } from 'react-router-dom';

import Preloader from '../../lib/layout/Preloader';
import Page from '../../lib/layout/Page';
import Column from '../../lib/layout/Column';
import UpdateGeneralInformationForm from './UpdateGeneralInformationForm';

import { useGetSystemQuery } from '../../lib/ascertis-api';

const GeneralInformationPage = () => {
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });

  if (!system) {
    return <Preloader />;
  }

  return (
    <Page>
      <Column small={12}>
        <UpdateGeneralInformationForm system={system} />
      </Column>
    </Page>
  );
};

GeneralInformationPage.propTypes = {};

export default GeneralInformationPage;
