import React from 'react';
import PropTypes from 'prop-types';

const ControlSummary = ({ name, description }) => {
  return (
    <div className="control-summary">
      <h4>{name}</h4>
      <div className="divider" />
      <br />
      <p>{description}</p>
    </div>
  );
};

ControlSummary.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default ControlSummary;
