import React from 'react';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import ImageUpload from '../../lib/forms/ImageUpload';
import FileUpload from '../../lib/forms/FileUpload';
import SubmitButton from '../../lib/forms/SubmitButton';
import ActionButton from '../../lib/forms/ActionButton';

import {
  useCreateDocumentMutation
} from '../../lib/ascertis-api';

const Schema = Yup.object().shape({
  document: Yup.object().shape({
    name: Yup.string().required(),
    base64: Yup.string().required()
  })
});

const DocumentUploadForm = ({ system, control, onUpload = () => {}, onClose = () => {}}) => {
  const [createDocument] = useCreateDocumentMutation();
  const handleSubmit = async (formValues) => {
    const body = {
      name: formValues.document.name,
      type: formValues.document.type,
      base64: formValues.document.base64,
      controls: [control.id]
    };
    await createDocument({ systemId: system.id, params: body }).unwrap()
      .then((payload) => {
        onUpload();
      }).catch((error) => {
      });
  };

  const handleClose = () => onClose();

  return (
    <div id="upload-document-form">
      <Row>
        <Column small={12}>
          <FormContainer title="Update Your Document">
            <Formik
              initialValues={{
                document: {
                  name: '',
                  base64: ''
                }
              }}
              enableReinitialize
              validationSchema={Schema}
              onSubmit={(values, actions) => {
                return handleSubmit(values, actions);
              }}
            >
              {props => (
                <Form role="form">
                  <Row>
                    <Column small={12}>
                      <Field
                        name="document"
                        id="attach-document"
                        component={FileUpload}
                        onChange={props.setFieldValue}
                      />
                    </Column>
                  </Row>

                  <Row className="form-button-container right-align">
                    <SubmitButton>Save</SubmitButton>
                    <ActionButton id="close-document-select" className="btn medium" onClick={handleClose} buttonText="Close" />
                  </Row>
                </Form>
              )}
            </Formik>
          </FormContainer>
        </Column>
      </Row>
    </div>
  );
};

DocumentUploadForm.propTypes = {
  onUpload: PropTypes.func,
  onClose: PropTypes.func
};

export default DocumentUploadForm;
