import React from 'react';
import { useParams } from 'react-router-dom';

import Preloader from '../../lib/layout/Preloader';
import SoftwareForm from './components/SoftwareForm';

import {
  useGetSystemQuery,
  useGetSoftwareListQuery
} from '../../lib/ascertis-api';

const SoftwareManager = () => {
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: list, isLoading: listLoading } = useGetSoftwareListQuery({ systemId });

  if (!system || listLoading) {
    return <Preloader />;
  }

  return (
    <div id="equipment-manager">
      <SoftwareForm system={system} softwareList={list} />
    </div>
  );
};

SoftwareManager.propTypes = {};

export default SoftwareManager;
