import React from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import Preloader from '../../lib/layout/Preloader';
import Tabs from '../../lib/layout/Tabs';

import SystemOwnerForm from './SystemOwnerForm';
import AuthorizingOfficerForm from './AuthorizingOfficerForm';
import CertifierForm from './CertifierForm';
import SeniorInformationSecurityOfficerForm from './SeniorInformationSecurityOfficerForm';
import KeyPersonForm from './KeyPersonForm';

import { useGetSystemQuery, useGetSystemUsersQuery } from '../../lib/ascertis-api';

const userFilter = (users, roleName) => {
  return _.filter(users, (user) => _.includes(user.roles, roleName));
}

const UserManagementPage = () => {
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: users } = useGetSystemUsersQuery({ systemId });
  const titles = ['System Owner', 'Authorizing Officer', 'Certifier', 'SISO', 'Key Persons'];

  if (!system || !users) {
    return <Preloader />;
  }

  const systemOwner = userFilter(users, 'SystemOwner')[0];
  const authorizingOfficer = userFilter(users, 'AuthorizingOfficer')[0];
  const certifier = userFilter(users, 'Certifier')[0];
  const siso = userFilter(users, 'SeniorInformationSecurityOfficer')[0];
  const keyPersons = userFilter(users, 'KeyPerson');

  return (
    <div id="user-management-section">
      <Tabs className="user-tabs" titles={titles}>
        <SystemOwnerForm key="system-owner" system={system} user={systemOwner} />
        <AuthorizingOfficerForm key="authorizing-officer" system={system} user={authorizingOfficer} />
        <CertifierForm key="certifier" system={system} user={certifier} />
        <SeniorInformationSecurityOfficerForm key="siso" system={system} user={siso} />
        <KeyPersonForm key="key-persons" system={system} users={keyPersons} />
      </Tabs>
    </div>
  );
};

export default UserManagementPage;
