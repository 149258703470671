import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import TextAreaInput from '../../lib/forms/TextAreaInput';
import SubmitButton from '../../lib/forms/SubmitButton';

import {
  useUpdateControlImplementationMutation
} from '../../lib/ascertis-api';

const sleep = (time) => {
  return new Promise((resolve) => { return setTimeout(resolve, time); });
};

const CustomAnswerForm = ({ system, control, nextControl, implementation, classification }) => {
  const autoAdvanceOnAnswer = false;
  const history = useHistory();
  const [dirty, setDirty] = useState(false);
  const [currentClassification, setCurrentClassification] = useState(control.controlClassification);
  const [modalMessage, setModalMessage] = useState(undefined);
  const [updateControl] = useUpdateControlImplementationMutation();
  const showModal = !_.isEmpty(modalMessage);

  useEffect(() => {
    setDirty(false);
    setCurrentClassification(control.controlClassification);
  }, [control.id]);

  useEffect(() => {
    const bothBlank = _.isEmpty(classification) && _.isEmpty(currentClassification)
    if ((!bothBlank) && (classification !== currentClassification)) {
      setDirty(true);
      setCurrentClassification(classification);
    } else {
      setDirty(false);
    }
  }, [control.id, classification]);

  const handleSubmit = async (formValues) => {
    const params = {
      ...formValues,
      controlClassification: classification,
      response: 'custom_answer'
    }
    await updateControl({ systemId: system.id, controlId: control.id, params }).unwrap()
      .then((payload) => {
        setModalMessage(undefined);
        setDirty(false);
        sleep(1200).then(() => {
          if (nextControl && autoAdvanceOnAnswer) {
            history.push(`/systems/${system.id}/controls/${nextControl.key}/implementation`);
          } else if (autoAdvanceOnAnswer) {
            history.push(`/systems/${system.id}`);
          }
        });
      }).catch((error) => {
        setModalMessage(error);
      });
  };

  let buttonText = 'SELECT';
  const textColor = 'white-text';
  let cardColor = 'blue-grey darken-1';
  const selected = (implementation.selectedAnswer === 'custom_answer');

  if (selected && dirty) {
    buttonText = 'SAVE CHANGES';
    cardColor = 'green lighten-2';
  } else if (selected) {
    buttonText = 'SELECTED';
    cardColor = 'green darken-1';
  }

  const Schema = Yup.object().shape({
    description: Yup.string().required()
  });

  return (
    <div id="custom-answer-form" className="answer-option custom-answer-option">
      <FormContainer title="Custom Answer" contentClasses={textColor} cardClasses={cardColor}>
        <Formik
          initialValues={{ description: implementation.customAnswer || '' }}
          enableReinitialize
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props =>(
            <Form role="form">
              <Row>
                <Column small={12}>
                  <Field
                    name="description"
                    prompt="Description"
                    component={TextAreaInput}
                  />
                </Column>
              </Row>

              <Row className="form-button-container right-align">
                <SubmitButton>{buttonText}</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
}

CustomAnswerForm.propTypes = {
  implementation: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    selectedAnswer: PropTypes.string,
    customAnswer: PropTypes.string,
    requirementMet: PropTypes.bool
  }).isRequired
};

export default CustomAnswerForm;
