import React from 'react';
import PropTypes from 'prop-types';
import FileInputComponent from 'react-file-input-previews-base64';

import { generateGuid } from './lib/guid-generator';

const ImageUpload = ({
  field,
  form: { touched, errors },
  id,
  onChange = () => {}
}) => {
  const inputId = id || generateGuid();
  const handleChange = (data) => {
    onChange(field.name, data);
  };

  const button = (<button className="btn blue darken-3 waves-effect waves-light" type="button">
    <i className="material-icons right">file_upload</i>Select File
  </button>);

  let errorField = null;
  if (touched[field.name] && errors[field.name]) {
    errorField = (
        <div className="form-error is-visible">
          <span>This is a required field</span>
        </div>
    );
  }

  return (
    <div className="file-upload single-file-upload">
      <FileInputComponent
        labelText="Select file"
        labelStyle={{ fontSize: 14, display: 'none' }}
        multiple={false}
        buttonComponent={button}
        inputId={inputId}
        callbackFunction={(fileData) => { handleChange(fileData); }}
        accept="image/*"
      />
      {errorField}
    </div>
  );
};

ImageUpload.propTypes = {
  id: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
      name: PropTypes.string,
      base64: PropTypes.string
    })
  }),
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  }),
  onChange: PropTypes.func
};

export default ImageUpload;
