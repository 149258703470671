import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Preloader from '../../lib/layout/Preloader';
import Tabs from '../../lib/layout/Tabs';
import Column from '../../lib/layout/Column';
import ReportsList from './ReportsList';
import ReportFormSelector from './ReportFormSelector';

import { selectUser } from '../../lib/userSlice';

import {
  useGetSystemQuery,
  useGetReportsQuery,
  useGetSystemUserQuery
} from '../../lib/ascertis-api';

const ReportsPage = () => {
  const authUser = useSelector(selectUser);
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: reports } = useGetReportsQuery({ systemId });
  const { data: reportUser } = useGetSystemUserQuery({ systemId, userId: authUser.id });
  const titles = ['Generated Reports', 'Create New Report'];

  if (!system || !reports || !reportUser) {
    return <Preloader />;
  }

  return (
    <div id="reports">
      <Column small={12}>
        <div id="report-section">
          <Tabs titles={titles}>
            <ReportsList reports={reports} key="generated-reports" />
            <ReportFormSelector system={system} user={reportUser} key="create-new-report" />
          </Tabs>
        </div>
      </Column>
    </div>
  );
};

export default ReportsPage;
