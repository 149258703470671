import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Modal from '../../lib/layout/Modal';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import TextInput from '../../lib/forms/TextInput';
import RadioSelectInput from '../../lib/forms/RadioSelectInput';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useCreateSystemProvisionRequestMutation } from '../../lib/ascertis-api';

const Schema = Yup.object().shape({
  companyName: Yup.string().required(),
  cmmcLevel: Yup.number().required(),
  userName: Yup.string().required(),
  userEmail: Yup.string().email().required(),
});

const NewSystemProvisionRequestForm = () => {
  const history = useHistory();
  const [createRequest] = useCreateSystemProvisionRequestMutation();
  const [modalMessage, setModalMessage] = useState(undefined);
  const showModal = !_.isEmpty(modalMessage);
  const successMessage = `Your request has been submitted successfully. \
When your assessment is ready, you will receive an email with your login \
details.`;

  const handleSubmit = async (formValues) => {
    await createRequest({ params: formValues }).unwrap()
      .then((payload) => {
        setModalMessage(successMessage);
      }).catch((error) => {
        setModalMessage(error);
      });
  };
  const handleClose = () => {
    setModalMessage(undefined);
    history.push(`/home`);
  };

  const cuiOptions = [
    { value: '2', label: 'Yes, we transmit or store CUI' },
    { value: '1', label: 'No, we do not transmit or store CUI' }
  ];

  return (
    <div id="new-system-provision-request-form">
      <Modal id="notification-modal" open={showModal} onClose={handleClose}>
        { modalMessage }
      </Modal>
      <FormContainer title="Provision New System">
        <Formik
          initialValues={{
            companyName: '',
            cmmcLevel: '',
            userName: '',
            userEmail: ''
          }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={12}>
                  <div className="divider" />
                  <div className="section">
                    <h6>Company Information</h6>
                  </div>
                </Column>
              </Row>
              <Row>
                <Column small={12}>
                  <Field
                    id="company-name"
                    name="companyName"
                    prompt="Company Name"
                    component={TextInput}
                  />
                </Column>
                <Row>
                  <Column small={12}>
                    <Field
                      id="cmmc-level"
                      name="cmmcLevel"
                      prompt="Handling of CUI (Controlled Unclassified Information)"
                      options={cuiOptions}
                      component={RadioSelectInput}
                      onChange={props.setFieldValue}
                    />
                  </Column>
                </Row>
              </Row>
              <Row>
                <Column small={12}>
                  <div className="divider" />
                  <div className="section">
                    <h6>User Details</h6>
                  </div>
                </Column>
              </Row>
              <Row>
                <Column small={6}>
                  <Field
                    id="user-name"
                    name="userName"
                    prompt="User Name"
                    component={TextInput}
                  />
                </Column>
                <Column small={6}>
                  <Field
                    id="user-email"
                    name="userEmail"
                    prompt="User Email"
                    component={TextInput}
                  />
                </Column>
              </Row>

              <Row className="form-button-container right-align">
                <SubmitButton>Submit</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
}

export default NewSystemProvisionRequestForm;
