import React from 'react';
import _ from 'lodash';

import Preloader from '../../lib/layout/Preloader';
import Column from '../../lib/layout/Column';
import Section from '../../lib/layout/Section';
import SolutionPartnerCard from './SolutionPartnerCard';

import NewSolutionPartnerForm from './NewSolutionPartnerForm';

import { useGetSolutionPartnersQuery } from '../../lib/ascertis-api';

const SolutionPartnersPage = () => {
  const { data: partners } = useGetSolutionPartnersQuery({});
  const formArea = <NewSolutionPartnerForm />;

  if (!partners) {
    return <Preloader />;
  }

  const listItems = _.map(partners, (partner) => {
    return (
      <SolutionPartnerCard key={partner.id} partner={partner} />
    );
  });

  return (
    <div id="solution_partners">
      <Column small={12}>
        <Section>
          <h5>Solution Partners</h5>
        </Section>
        <Section>
          {formArea}
        </Section>
        <Section>
          {listItems}
        </Section>
      </Column>
    </div>
  );
};

SolutionPartnersPage.propTypes = {
};

export default SolutionPartnersPage;
