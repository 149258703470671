import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import CapabilitySummaryCell from './CapabilitySummaryCell';

const CapabilitiesTableRow = ({ capability, controls, system }) => {
  const showLevelOne = system.cmmcLevel >= 1;
  const showLevelTwo = system.cmmcLevel >= 2;
  const showLevelThree = system.cmmcLevel >= 3;

  const levelOneControls = _.map(capability.cmmcLevelOneControlIds, (id) => {
    const controlId = id.toUpperCase();
    return  _.find(controls, ['id', controlId]);
  });

  const levelTwoControls = _.map(capability.cmmcLevelTwoControlIds, (id) => {
    const controlId = id.toUpperCase();
    return  _.find(controls, ['id', controlId]);
  });

  const levelThreeControls = _.map(capability.cmmcLevelThreeControlIds, (id) => {
    const controlId = id.toUpperCase();
    return  _.find(controls, ['id', controlId]);
  });

  let rowClasses = 'capability-table-row';
  let levelOneRow = undefined;
  if (showLevelOne) {
    levelOneRow = (
      <td>
        <CapabilitySummaryCell system={system} controls={levelOneControls} pass={capability.cmmcLevelOnePass} />
      </td>
    );
  }

  let levelTwoRow = undefined;
  if (showLevelTwo) {
    levelTwoRow = (
      <td>
        <CapabilitySummaryCell system={system} controls={levelTwoControls} pass={capability.cmmcLevelTwoPass} />
      </td>
    );
  }

  let levelThreeRow = undefined;
  if (showLevelThree) {
    levelThreeRow = (
      <td>
        <CapabilitySummaryCell system={system} controls={levelThreeControls} pass={capability.cmmcLevelThreePass} />
      </td>
    );
  }

  return (
      <tr key={`input-${capability.id}`} className={rowClasses}>
        <td>{capability.category}</td>
        <td>{capability.name}</td>
        { levelOneRow }
        { levelTwoRow }
        { levelThreeRow }
      </tr>
  );
};

CapabilitiesTableRow.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired,
    cmmcLevel: PropTypes.number.isRequired
  }).isRequired,
  controls: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  capability: PropTypes.shape({
    id: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    cmmcLevelOnePass: PropTypes.bool,
    cmmcLevelTwoPass: PropTypes.bool,
    cmmcLevelThreePass: PropTypes.bool
  }).isRequired
};

export default CapabilitiesTableRow;
